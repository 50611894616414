<template>
  <div class="qr-page">
    <div class="qr-logo">
      <img src="@/assets/images/publist/publist_text.png" alt="" />
    </div>
    <div class="qr-box">
      <div class="qr-header"></div>
      <div class="qr-code">
        <div class="qr-content">
          <div class="qr">
            <vueQr :text="searUrl" :size="200" />
          </div>
          <div class="qr-txt">
            <div>1.截图保存二维码</div>
            <div>2.使用抖音识别二维码发布视频</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { getPublishLink } from "@/api/publish";
export default {
  components: {
    vueQr,
  },
  name: "qr",
  data() {
    return {
      searUrl: "http://www.baidu.com",
    };
  },
  created() {
    let releaseId = this.$route.query.releaseId;
    getPublishLink({ releaseId }).then((res) => {
      console.log(res);
    });
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.qr-page {
  height: 100vh;
  background-image: url("../../assets/images/publist/publist_bg.jpg");
  background-size: 100% 100%;
}
.qr-logo {
  line-height: 0;
  //   padding: 10px;
  img {
    width: 100%;
  }
}
.qr-box {
  padding: 10px;
  margin-top: -56px;
  .qr-header {
    height: 60px;
    background-image: url("../../assets/images/publist/qr_hd.png");
    background-size: 100% 100%;
  }
  .qr-code {
    padding: 0 36px;
    margin-top: -32px;
    .qr-content {
      padding: 20px;
      background-color: #fff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .qr {
        text-align: center;
        width: 200px;
        padding: 10px;
        margin: 20px auto;
        background-image: url(../../assets/images/publist/qr_box.png);
        background-size: 100%;
      }
      .qr-txt {
        color: #349ffd;
      }
    }
  }
}
</style>